import React from "react";
import styled from "styled-components";

const LogoSvg = styled.svg`
  width: 400px;
  height: 80px;
  @media (max-width: 700px) {
    width: 300px;
    height: 50px;
  }

  @media (max-width: 450px) {
    width: 250px;
    height: 50px;
  }
`;

const Logo = () => {
  return (
    <LogoSvg
      viewBox="0 0 802 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170 111.135C166.687 111.135 164 108.448 164 105.135V71.5681C164 49.7494 181.751 32.0001 203.568 32.0001C206.881 32.0001 209.568 34.6868 209.568 38.0001C209.568 41.3134 206.881 44.0001 203.568 44.0001C188.367 44.0001 176 56.3668 176 71.5681V105.135C176 108.448 173.313 111.135 170 111.135Z"
        fill="url(#paint0_linear_6_536)"
      />
      <path
        d="M111.568 44.1349C125.368 44.1349 136.8 54.3389 138.795 67.5975H84.34C86.3333 54.3415 97.7653 44.1349 111.568 44.1349ZM151.135 71.7015C151.135 49.8842 133.385 32.1349 111.568 32.1349C89.7507 32.1349 72 49.8842 72 71.7015C72 93.5202 89.7507 111.27 111.568 111.27C117.576 111.27 123.349 109.956 128.725 107.363C131.711 105.924 132.964 102.338 131.525 99.3535C130.087 96.3669 126.499 95.1149 123.515 96.5535C119.776 98.3562 115.757 99.2695 111.568 99.2695C98.8227 99.2695 88.1027 90.5655 84.96 78.7975H145.137H145.144C148.457 78.7935 151.14 76.1029 151.137 72.7909L151.135 71.7015Z"
        fill="url(#paint1_linear_6_536)"
      />
      <path
        d="M53 109.667H6.00003C3.76269 109.667 1.71069 108.422 0.678693 106.438C-0.355973 104.454 -0.197306 102.059 1.08403 100.226L41.4747 44.135H6.00003C2.68669 44.135 2.81229e-05 41.4483 2.81229e-05 38.135C2.81229e-05 34.8217 2.68669 32.135 6.00003 32.135H53C55.2374 32.135 57.2894 33.3803 58.3214 35.3643C59.356 37.3483 59.1974 39.743 57.916 41.5763L17.5254 97.667H53C56.3134 97.667 59 100.354 59 103.667C59 106.98 56.3134 109.667 53 109.667Z"
        fill="url(#paint2_linear_6_536)"
      />
      <path
        d="M775.215 109.409C754.02 109.409 748.011 93.0012 747.765 92.3018C746.675 89.2024 748.304 85.8042 751.405 84.7159C754.508 83.6224 757.761 85.3083 758.991 88.3561C762.595 97.2708 773.914 97.5088 775.215 97.5088C785.332 97.5088 789.86 91.8786 789.86 86.3C789.86 78.7909 781.521 75.6095 774.523 74.2621C758.008 71.0834 749.636 63.6431 749.636 52.15C749.636 41.6049 758.225 30.9303 774.644 30.9303C791.434 30.9303 799.037 43.8435 799.353 44.3922C800.987 47.243 800.003 50.8793 797.154 52.5149C794.319 54.14 791.253 53.4881 789.059 50.3636C788.796 49.9881 784.337 42.8307 774.644 42.8307C766.926 42.8307 761.536 46.6626 761.536 52.15C761.536 54.3912 761.536 59.6432 776.773 62.576C799.325 66.917 801.761 80.6143 801.761 86.3C801.761 97.7839 792.642 109.409 775.215 109.409Z"
        fill="#197CD8"
      />
      <path
        d="M726.394 109.408C723.108 109.408 720.444 106.743 720.444 103.458V66.1751C720.444 53.3028 709.973 42.8305 697.099 42.8305C684.227 42.8305 673.753 53.3028 673.753 66.1751V103.458C673.753 106.743 671.09 109.408 667.803 109.408C664.518 109.408 661.853 106.743 661.853 103.458V66.1751C661.853 46.7405 677.664 30.9302 697.099 30.9302C716.534 30.9302 732.344 46.7405 732.344 66.1751V103.458C732.344 106.743 729.68 109.408 726.394 109.408Z"
        fill="#197CD8"
      />
      <path
        d="M609.332 42.8307C594.259 42.8307 581.993 55.0946 581.993 70.1697C581.993 85.2448 594.259 97.5088 609.332 97.5088C624.406 97.5088 636.672 85.2448 636.672 70.1697C636.672 55.0946 624.406 42.8307 609.332 42.8307ZM609.332 109.409C587.696 109.409 570.093 91.8072 570.093 70.1697C570.093 48.5323 587.696 30.9303 609.332 30.9303C630.969 30.9303 648.572 48.5323 648.572 70.1697C648.572 91.8072 630.969 109.409 609.332 109.409Z"
        fill="#197CD8"
      />
      <path
        d="M551.299 109.406C548.013 109.406 545.349 106.742 545.349 103.456V36.8802C545.349 33.5944 548.013 30.93 551.299 30.93C554.585 30.93 557.249 33.5944 557.249 36.8802V103.456C557.249 106.742 554.585 109.406 551.299 109.406Z"
        fill="#197CD8"
      />
      <path
        d="M525.821 97.5591C510.747 97.5591 498.482 85.2951 498.482 70.22V42.9061H525.821C528.887 42.9061 531.374 40.4189 531.374 37.3513C531.374 34.2849 528.887 31.7978 525.821 31.7978H498.482V5.9502C498.482 2.66438 495.819 1.82793e-05 492.532 1.82793e-05C489.246 1.82793e-05 486.582 2.66438 486.582 5.9502V70.22C486.582 91.8575 504.185 109.459 525.821 109.459C529.107 109.459 531.771 106.795 531.771 103.509C531.771 100.223 529.107 97.5591 525.821 97.5591Z"
        fill="#197CD8"
      />
      <path
        d="M406.704 70.0902C406.704 55.0456 418.948 42.8065 434 42.8065C449.05 42.8065 461.297 55.0456 461.297 70.0902C461.297 85.1348 449.05 97.3752 434 97.3752C418.948 97.3752 406.704 85.1348 406.704 70.0902ZM394.822 70.0902V127.062C394.822 130.341 397.482 133 400.763 133C404.043 133 406.704 130.341 406.704 127.062V97.3633C414.134 104.896 423.395 109.251 434 109.251C455.603 109.251 473.178 91.6838 473.178 70.0902C473.178 48.4965 455.603 30.9302 434 30.9302C412.396 30.9302 394.822 48.4965 394.822 70.0902Z"
        fill="#197CD8"
      />
      <path
        d="M344.737 32.9831C334.325 32.9831 329.816 38.4535 321.611 38.4535C316.412 38.4535 313.189 35.8855 309.235 33.0366C303.765 29.091 293.177 17 273.612 17C246.546 17 222.838 39.4031 223.001 70.5C222.838 101.597 246.546 124 273.612 124C293.177 124 303.765 111.909 309.235 107.963C313.176 105.114 316.412 102.546 321.611 102.546C329.816 102.546 334.325 108.017 344.737 108.017C362.69 108.017 376 90.9637 376 70.4064C376 49.8356 362.677 32.9831 344.737 32.9831ZM345.441 89.3588C341.988 89.3588 339.7 88.0881 336.762 86.5099C332.51 84.2361 326.81 81.7618 320.907 81.7618C311.348 81.7618 304.402 86.1755 299.189 89.4657C294.531 92.4082 289.887 95.3641 281.899 95.3641C269.307 95.3641 258.231 84.6374 258.231 70.5C258.231 56.376 269.307 45.6359 281.899 45.6359C289.887 45.6359 294.531 48.5918 299.189 51.5343C304.402 54.8245 311.348 59.2382 320.907 59.2382C326.81 59.2382 332.51 56.7772 336.762 54.4901C339.7 52.9252 341.975 51.6412 345.441 51.6412C353.673 51.6412 359.779 60.2815 359.779 70.5C359.779 80.7185 353.673 89.3588 345.441 89.3588Z"
        fill="url(#paint3_linear_6_536)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6_536"
          x1="2.5"
          y1="71.5674"
          x2="477.5"
          y2="71.5674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#31EDA9" />
          <stop offset="0.0618344" stop-color="#71EF74" />
          <stop offset="0.1263" stop-color="#AEF043" />
          <stop offset="0.1813" stop-color="#DAF11E" />
          <stop offset="0.2239" stop-color="#F5F208" />
          <stop offset="0.2488" stop-color="#FFF200" />
          <stop offset="0.27" stop-color="#FEDC0E" />
          <stop offset="0.3843" stop-color="#FA685A" />
          <stop offset="0.4629" stop-color="#F71F89" />
          <stop offset="0.4991" stop-color="#F6029C" />
          <stop offset="0.505" stop-color="#F0059E" />
          <stop offset="0.5554" stop-color="#BE21AB" />
          <stop offset="0.6084" stop-color="#9239B7" />
          <stop offset="0.663" stop-color="#6C4EC1" />
          <stop offset="0.7196" stop-color="#4E5FCA" />
          <stop offset="0.7788" stop-color="#376CD0" />
          <stop offset="0.8419" stop-color="#2675D4" />
          <stop offset="0.9114" stop-color="#1C7AD7" />
          <stop offset="1" stop-color="#197CD8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6_536"
          x1="-0.499992"
          y1="71.7022"
          x2="475.5"
          y2="71.7022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#31EDA9" />
          <stop offset="0.0618344" stop-color="#71EF74" />
          <stop offset="0.1263" stop-color="#AEF043" />
          <stop offset="0.1813" stop-color="#DAF11E" />
          <stop offset="0.2239" stop-color="#F5F208" />
          <stop offset="0.2488" stop-color="#FFF200" />
          <stop offset="0.27" stop-color="#FEDC0E" />
          <stop offset="0.3843" stop-color="#FA685A" />
          <stop offset="0.4629" stop-color="#F71F89" />
          <stop offset="0.4991" stop-color="#F6029C" />
          <stop offset="0.505" stop-color="#F0059E" />
          <stop offset="0.5554" stop-color="#BE21AB" />
          <stop offset="0.6084" stop-color="#9239B7" />
          <stop offset="0.663" stop-color="#6C4EC1" />
          <stop offset="0.7196" stop-color="#4E5FCA" />
          <stop offset="0.7788" stop-color="#376CD0" />
          <stop offset="0.8419" stop-color="#2675D4" />
          <stop offset="0.9114" stop-color="#1C7AD7" />
          <stop offset="1" stop-color="#197CD8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6_536"
          x1="0.00032016"
          y1="70.901"
          x2="475.5"
          y2="70.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#31EDA9" />
          <stop offset="0.0618344" stop-color="#71EF74" />
          <stop offset="0.1263" stop-color="#AEF043" />
          <stop offset="0.1813" stop-color="#DAF11E" />
          <stop offset="0.2239" stop-color="#F5F208" />
          <stop offset="0.2488" stop-color="#FFF200" />
          <stop offset="0.27" stop-color="#FEDC0E" />
          <stop offset="0.3843" stop-color="#FA685A" />
          <stop offset="0.4629" stop-color="#F71F89" />
          <stop offset="0.4991" stop-color="#F6029C" />
          <stop offset="0.505" stop-color="#F0059E" />
          <stop offset="0.5554" stop-color="#BE21AB" />
          <stop offset="0.6084" stop-color="#9239B7" />
          <stop offset="0.663" stop-color="#6C4EC1" />
          <stop offset="0.7196" stop-color="#4E5FCA" />
          <stop offset="0.7788" stop-color="#376CD0" />
          <stop offset="0.8419" stop-color="#2675D4" />
          <stop offset="0.9114" stop-color="#1C7AD7" />
          <stop offset="1" stop-color="#197CD8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6_536"
          x1="-1.50001"
          y1="71"
          x2="470.5"
          y2="71"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#31EDA9" />
          <stop offset="0.0618344" stop-color="#71EF74" />
          <stop offset="0.1263" stop-color="#AEF043" />
          <stop offset="0.1813" stop-color="#DAF11E" />
          <stop offset="0.2239" stop-color="#F5F208" />
          <stop offset="0.2488" stop-color="#FFF200" />
          <stop offset="0.27" stop-color="#FEDC0E" />
          <stop offset="0.3843" stop-color="#FA685A" />
          <stop offset="0.4629" stop-color="#F71F89" />
          <stop offset="0.4991" stop-color="#F6029C" />
          <stop offset="0.505" stop-color="#F0059E" />
          <stop offset="0.5554" stop-color="#BE21AB" />
          <stop offset="0.6084" stop-color="#9239B7" />
          <stop offset="0.663" stop-color="#6C4EC1" />
          <stop offset="0.7196" stop-color="#4E5FCA" />
          <stop offset="0.7788" stop-color="#376CD0" />
          <stop offset="0.8419" stop-color="#2675D4" />
          <stop offset="0.9114" stop-color="#1C7AD7" />
          <stop offset="1" stop-color="#197CD8" />
        </linearGradient>
      </defs>
    </LogoSvg>
  );
};

export default Logo;
