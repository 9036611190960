import axios from 'axios'
import React from 'react'
import { useAccount, useDisconnect, useSignMessage } from 'wagmi'
import { Result, Spin } from 'antd'
import Checkbox from '@mui/material/Checkbox'
import { FormControlLabel, Alert } from '@mui/material'
import './App.css'
import {
  BodyContainer,
  BodyContainerChild,
  ButtonContainer,
  ContainerForm,
  CustomButton,
  CustomButtonSign,
  CustomInput,
  FormContainer,
  InputContainer,
  LogoContainer,
  MainContainer,
  OpiumLink,
  ResultTitleCustom,
  SelectCustom,
  TypographyTitleCustom,
  TypographyTitleDirectionCustom,
  TypographyTitleDirectionCustomBtn,
} from './AppStyled'
import { LoadingOutlined } from '@ant-design/icons'
import Logo from './Logo'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { countries } from './constns'

const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

const messageToSign = (
  termsChecked: boolean,
  newsChecked: boolean,
  country: string,
  telegramInput: string,
  emailInput: string
) => {
  const messageObject = {
    terms: termsChecked,
    news: newsChecked,
    country,
    telegram: telegramInput,
    email: emailInput,
    timestemp: new Date().getTime(),
  }

  const message = `I accept Terms Of Service, Privacy policy and Disclaimer. I declare that I am not a US person (as defined in the Securities Act of 1933, as amended); or a citizen or resident of Canada; or a citizen or resident of Japan; or based on other prohibited jurisdictions mentioned in Terms of Service.${JSON.stringify(
    messageObject
  )}`

  return message
}

const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  return re.test(String(email).toLowerCase())
}

function App() {
  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const { disconnect } = useDisconnect()

  const [emailInput, setEmailInput] = React.useState('')
  const [telegramInput, setTelegramInput] = React.useState('')
  const [country, setCountry] = React.useState('')

  const [termsChecked, setTermsChecked] = React.useState(false)
  const [newsChecked, setNewsChecked] = React.useState(false)

  const [selectValues, setSelectValues] = React.useState<{value: string, label: string}[]>([])
  const [signature, setSignature] = React.useState('')

  const [isSendForm, setIsSendForm] = React.useState(false)
  const [statusSendForm, setStatusSendForm] = React.useState('')
  const [isLoadUser, setIsLoadUser] = React.useState(false)
  const [isAccessUser, setIsAccessUser] = React.useState<boolean | null>(null)

  const [errorForm, setErrorForm] = React.useState<any>({})
  const [signError] = React.useState<any>('')
  const [sendFormError, setSendFormError] = React.useState<any>('')

  const [userSignMessage, setUserSignMessage] = React.useState('')

  const [userUnits, setUserUnits] = React.useState('')
  const [userLanguage, setUserLanguage] = React.useState('')
  const [userTimezone, setUserTimezone] = React.useState('')


  const { data: dataSignMessage, signMessage } = useSignMessage({})

  React.useEffect(() => {
    if (!address || signature || dataSignMessage) return

    let isError = false

    if (emailInput && !validateEmail(emailInput)) {
      setErrorForm((prev: any) => ({ ...prev, email: 'error' }))
      isError = true
    } else {
      setErrorForm((prev: any) => ({ ...prev, email: '' }))
    }

    if (!country) {
      setErrorForm((prev: any) => ({ ...prev, country: 'error' }))
      isError = true
    } else {
      setErrorForm((prev: any) => ({ ...prev, country: '' }))
    }

    if (!termsChecked) {
      setErrorForm((prev: any) => ({ ...prev, terms: 'error' }))
      isError = true
    } else {
      setErrorForm((prev: any) => ({ ...prev, terms: '' }))
    }

    if (isError) {
      disconnect()

      return
    }

    setIsSendForm(true)

    const message: any = messageToSign(termsChecked, newsChecked, country, telegramInput, emailInput)

    setUserSignMessage(message)

    signMessage({ message })
  }, [address])

  React.useEffect(() => {
    if (!dataSignMessage) return
    const createSignature = `${dataSignMessage}`

    setSignature(createSignature)

    axios
      .post('https://zero-options-landing-backend-c4sc3xeaaa-ez.a.run.app/form/send-form', {
        signature: createSignature,
        email: emailInput,
        telegram: telegramInput,
        terms: termsChecked,
        news: newsChecked,
        country,
        recoveredAddress: address,
        units: userUnits,
        language: userLanguage,
        timezone: userTimezone,
        signMessage: userSignMessage,
      })
      .then((data) => {
        setSendFormError('')

        setStatusSendForm('success')
      })
      .catch((err) => {
        setSendFormError('Error send form')
        setStatusSendForm('error')
      })
      .finally(() => {
        disconnect()
        setIsSendForm(false)
      })
  }, [dataSignMessage])

  const isRestrictedUser = async () => {
    const response = await fetch('https://utils-server.opium.finance/v1/trace')
    const body = await response.json()
    return !body.access
  }

  React.useEffect(() => {
    setIsLoadUser(true)
    setSelectValues(countries)

    isRestrictedUser()
      .then((data: any) => {
        setIsAccessUser(true)
      })
      .catch((err) => {
        setIsAccessUser(null)
      })
      .finally(() => setIsLoadUser(false))
    
    disconnect()

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const language = navigator.language

    let units

    if (['en-US', 'en-GB', 'mm-DD'].includes(language)) {
      units = 'imperial'
    } else {
      units = 'metric'
    }

    setUserUnits(units)
    setUserLanguage(language)
    setUserTimezone(timezone)
  }, [])

  return statusSendForm === 'success' ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'drop-shadow(0 0 2em #646cffaa)',
        height: '70vh',
      }}
    >
      <Result icon={<Logo />} title={<ResultTitleCustom>Great! You are all set. Thank you!!</ResultTitleCustom>} />
    </div>
  ) : (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isLoadUser ? (
        <div style={{ position: 'absolute', zIndex: 1000 }}>
          <Spin
            spinning={true}
            style={{ width: '100%', height: 350, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            indicator={<LoadingOutlined style={{ fontSize: 54, color: '#5468ff', backgroundClip: 'transparent' }} />}
          />
        </div>
      ) : (
        ''
      )}

      {!isAccessUser && isAccessUser !== null ? (
        <div style={{ position: 'absolute', maxWidth: 600, textAlign: 'center', zIndex: 1000 }}>
          <ResultTitleCustom>
            Based on your IP address, you are breaking our Terms of Service related to prohibited jurisdictions,
            sanction lists, and black listed VPNs
          </ResultTitleCustom>
        </div>
      ) : (
        ''
      )}

      <ContainerForm isAccessUser={isAccessUser}>
        <LogoContainer>
          <a href="https://opium.network/" target="_blank" style={{ width: 'max-content' }}>
            <Logo />
          </a>
        </LogoContainer>

        <MainContainer>
          {isSendForm ? (
            <Spin
              spinning={true}
              style={{ width: '100%', height: 350, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              indicator={<LoadingOutlined style={{ fontSize: 54, color: '#5468ff', backgroundClip: 'transparent' }} />}
            />
          ) : (
            ''
          )}

          {!isSendForm && statusSendForm !== 'success' ? (
            <BodyContainer>
              <BodyContainerChild>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginBottom: 20,
                    gap: 10,
                  }}
                >
                  <TypographyTitleCustom>Welcome to the Early Access List for 0options</TypographyTitleCustom>

                  <TypographyTitleDirectionCustom>
                    Explore the innovative Web3 platform that specializes in a diverse range of crypto derivatives,
                    including oracle-free options, altcoin options, short-term and even zero-day options, among others!
                  </TypographyTitleDirectionCustom>

                  <TypographyTitleDirectionCustom>
                    By registering for early Beta access with your email or Telegram handle, you'll be whitelisted to
                    aquire “points” and gain first access to the platform, designed with intent-based architecture and
                    compliance tools. This is a unique opportunity for market makers, arbitrageurs, and solvers to join
                    a cutting-edge trading community and help shape the future of crypto derivatives.
                  </TypographyTitleDirectionCustom>

                  <TypographyTitleDirectionCustom>
                    There are zero options — your only choice is to register! Join now and be among the first to explore
                    the possibilities.
                  </TypographyTitleDirectionCustom>
                </div>

                <div>
                  <FormContainer>
                    <InputContainer>
                      <CustomInput
                        status={errorForm.email}
                        type="email"
                        placeholder={'Email (Optional. Get extra points)'}
                        style={{ backgroundColor: 'transparent' }}
                        onChange={(value) => setEmailInput(value.currentTarget.value)}
                      />
                      <CustomInput
                        placeholder="Telegram [Reference code] (Optional. Get extra points)"
                        style={{ backgroundColor: 'transparent' }}
                        onChange={(value) => setTelegramInput(value.currentTarget.value)}
                      />
                      <SelectCustom
                        className="custom-select"
                        showSearch
                        placeholder="Country"
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={selectValues}
                        errorForm={errorForm}
                        onChange={(value: any) => setCountry(value)}
                        style={{}}
                      />
                    </InputContainer>

                    <FormControlLabel
                      className="custom-form-control-label"
                      control={
                        <Checkbox
                          style={{ width: 'max-content', color: '#5d5f7c' }}
                          color="secondary"
                          onChange={(_, checked) => setTermsChecked(checked)}
                        />
                      }
                      label={
                        <p style={errorForm && errorForm.terms === 'error' ? { color: 'red' } : {}}>
                          By checking this box, I accept{' '}
                          <OpiumLink
                            href="https://opium.network/terms-of-service.html"
                            onClick={(e) => e.stopPropagation()}
                            target="_blink"
                          >
                            Terms Of Service
                          </OpiumLink>
                          ,{' '}
                          <OpiumLink
                            href="https://opium.network/privacy.html"
                            onClick={(e) => e.stopPropagation()}
                            target="_blink"
                          >
                            Privacy policy
                          </OpiumLink>{' '}
                          and{' '}
                          <OpiumLink
                            href="https://opium.network/disclaimer.html"
                            onClick={(e) => e.stopPropagation()}
                            target="_blink"
                          >
                            Disclaimer
                          </OpiumLink>
                          . I declare that I am not a US person (as defined in the Securities Act of 1933, as amended);
                          or a citizen or resident of Canada; or a citizen or resident of Japan; or based on other
                          prohibited jurisdictions mentioned in Terms of Service.
                        </p>
                      }
                    />
                    <FormControlLabel
                      className="custom-form-control-label"
                      control={
                        <Checkbox
                          style={{ width: 'max-content', color: '#5d5f7c' }}
                          color="secondary"
                          onChange={(_, checked) => setNewsChecked(checked)}
                        />
                      }
                      label={
                        <p>
                          Subscribe to the 0options newsletter to stay up-to-date on our news and updates. You can
                          unsubscribe at any time.
                        </p>
                      }
                    />
                  </FormContainer>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: 10 }}>
                    <TypographyTitleDirectionCustomBtn>
                      Sign this form with your crypto wallet, ensuring your wallet will be whitelisted for early access.
                    </TypographyTitleDirectionCustomBtn>
                  </div>

                  <ButtonContainer>
                    <CustomButton
                      type="primary"
                      onClick={async () => {
                        setIsSendForm(true)
                        let isError = false

                        if (emailInput && !validateEmail(emailInput)) {
                          setErrorForm((prev: any) => ({ ...prev, email: 'error' }))
                          isError = true
                        } else {
                          setErrorForm((prev: any) => ({ ...prev, email: '' }))
                        }

                        if (!country) {
                          setErrorForm((prev: any) => ({ ...prev, country: 'error' }))
                          isError = true
                        } else {
                          setErrorForm((prev: any) => ({ ...prev, country: '' }))
                        }

                        if (!termsChecked) {
                          setErrorForm((prev: any) => ({ ...prev, terms: 'error' }))
                          isError = true
                        } else {
                          setErrorForm((prev: any) => ({ ...prev, terms: '' }))
                        }

                        if (isError) {
                          disconnect()
                          setIsSendForm(false)
                          return
                        }

                        await axios
                          .post('https://zero-options-landing-backend-c4sc3xeaaa-ez.a.run.app/form/send-form', {
                            email: emailInput,
                            telegram: telegramInput,
                            terms: termsChecked,
                            news: newsChecked,
                            country,
                            units: userUnits,
                            language: userLanguage,
                            timezone: userTimezone,
                          })
                          .then((data) => {
                            setSendFormError('')
                            setStatusSendForm('success')
                          })
                          .catch((err) => {
                            setSendFormError('Error send form')
                            setStatusSendForm('error')
                          })
                          .finally(() => {
                            disconnect()
                            setIsSendForm(false)
                          })
                      }}
                    >
                      Send
                    </CustomButton>

                    <CustomButtonSign onClick={() => open()}>sign</CustomButtonSign>
                  </ButtonContainer>

                  {signError ? (
                    <Alert variant="outlined" severity="error">
                      {signError}
                    </Alert>
                  ) : (
                    ''
                  )}

                  {sendFormError ? (
                    <Alert variant="outlined" severity="error">
                      {sendFormError}
                    </Alert>
                  ) : (
                    ''
                  )}
                </div>
              </BodyContainerChild>
            </BodyContainer>
          ) : (
            ''
          )}
        </MainContainer>
      </ContainerForm>
    </div>
  )
}

export default App
