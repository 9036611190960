import styled from 'styled-components'
import { Button, Input, Select, Typography } from 'antd'
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons'

export const SelectCustom = styled(Select)<any>`
  width: 100%;
  border-color: red;
  border: ${(prop: any) => (prop.errorForm && prop.errorForm.country === 'error' ? '1px solid red' : 'none')};
  border-radius: ${(prop: any) => (prop.errorForm && prop.errorForm.country === 'error' ? '7px' : 'none')};

  @media (max-width: 700px) {
    height: 29px;
  }

  @media (max-width: 450px) {
    height: 27px;
  }
`

export const ContainerForm = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  filter: ${(prop: any) => (prop.isAccessUser ? 'drop-shadow(0 0 2em #646cffaa)' : 'blur(15px)')};
  user-select: ${(prop: any) => (prop.isAccessUser ? 'auto' : 'none')};
  pointer-events: ${(prop: any) => (prop.isAccessUser ? 'auto' : 'none')};
`

export const MainContainer = styled.div`
  padding: 0 20px;
`

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const BodyContainerChild = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 500px;
`

export const CustomInput = styled(Input)`
  &&&::placeholder {
    color: #c0c0c0; // Здесь вы можете указать нужный цвет
  }
  &&:hover {
    border-color: #006bd6;
  }

  &&:focus {
    border-color: #5468ff;
  }
  color: #c0c0c0;
  border-color: #5d5f7c;
  background-color: transparent; // Это ваш текущий стиль фона

  @media (max-width: 700px) {
    font-size: 11px !important;
  }

  @media (max-width: 450px) {
    font-size: 10px !important;
  }
`

export const CustomButton = styled(Button)`
  width: 100%;
  height: 40px;
  border: 1px solid rgb(25, 124, 216);
  border-radius: 13px;

  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
  color: white;
  padding: 0.625rem 3rem;
  background-color: rgba(25, 124, 216, 0.29);
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 11px;
  }

  @media (max-width: 450px) {
    font-size: 10px;
  }
`

export const CustomButtonSign = styled(CustomButton)`
  background-color: rgba(25, 124, 216);
`

export const CustomButtonDisabled = styled(Button)`
  width: 100%;
  height: 40px;
  border: 1px solid rgb(25, 124, 216);
  border-radius: 13px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  font-size: 0.9rem;
  color: white;
  padding: 0.625rem 3rem;
  background-color: rgb(25, 124, 216);
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 11px;
    height: 35px;
  }

  @media (max-width: 450px) {
    font-size: 10px;
    height: 32px;
  }
`

export const TypographyTitleCustom = styled.p`
  font-weight: 1000;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: white;
  font-size: 35px;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 7px;
  }

  @media (max-width: 450px) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const TypographyTitleDirectionCustom = styled(Typography.Text)`
  color: white;
  font-size: 13px;

  @media (max-width: 700px) {
    font-size: 11px;
  }

  @media (max-width: 450px) {
    font-size: 9px;
  }
`

export const TypographyTitleDirectionCustomBtn = styled(TypographyTitleDirectionCustom)`
  color: rgb(25, 124, 216);
  font-size: 10px;

  @media (max-width: 700px) {
    font-size: 10px;
  }

  @media (max-width: 450px) {
    font-size: 8px;
  }
`

export const FormContainer = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 450px) {
    margin-bottom: 20px;
    gap: 5px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  @media (max-width: 450px) {
    gap: 10px;
  }
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;

  @media (max-width: 700px) {
    margin-bottom: 5px;
  }
`

export const SmileOutlinedCustom = styled(SmileOutlined)`
  color: rgb(25, 124, 216) !important;
  width: 80px;
  height: 80px;

  @media (max-width: 700px) {
    width: 60px;
    height: 60px;
  }
`
// color: rgb(25, 124, 216);
export const ResultTitleCustom = styled.span`
  color: rgb(25, 124, 216);
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 700px) {
    font-size: 15px;
  }
`

export const OpiumLink = styled.a`
  color: rgb(25, 124, 216);
`
